const howScrolling = () => {
  const howSections = document.querySelectorAll(".how-section");

  const setCenterSectionContent = () => {
    howSections.forEach((el) => {
      const content = el.querySelector(".how-section-content");
      const contentHeight = content.scrollHeight;
      console.log(contentHeight, "contentHeight");
      const top = window.innerHeight / 2 - contentHeight;
      content.style.top = `${top}px`;
    });
  };

  setTimeout(() => {
    // setCenterSectionContent();
  }, 1000);

  window.addEventListener("resize", () => {
    // setCenterSectionContent();
  });

  let lastScrollPos;

  document
    .querySelector("#how-detail-screen .spaced")
    .addEventListener("scroll", (event) => {
      const singleSection = event.target.scrollHeight / 3;
      let scroll = event.target.scrollTop;

      if (scroll <= singleSection) {
        let opacity = 1 - scroll / 1000;
        const content = howSections[0].querySelector(".how-section-content h4");
        lastScrollPos = scroll;
        if (opacity >= 0.5) {
          content.style.color = `rgba(256, 256, 256, ${opacity})`;
        }
      } else if (scroll >= singleSection && scroll <= singleSection * 2) {
        let opacity = 1 - (scroll - singleSection) / 1000;

        const content = howSections[1].querySelector(".how-section-content h4");
        if (opacity >= 0.5) {
          content.style.color = `rgba(256, 256, 256, ${opacity})`;
        } else {
          lastScrollPos = scroll;
        }
      } else if (scroll >= singleSection * 2) {
        let opacity = 1 - (scroll - singleSection * 2) / 1000;
        const content = howSections[2].querySelector(".how-section-content h4");

        if (opacity >= 0.5) {
          content.style.color = `rgba(256, 256, 256, ${opacity})`;
        }
      }
    });

  const marquees = document.querySelectorAll(".marques .marquee");
  const howDetailScreen = document.getElementById("how-detail-screen");

  marquees.forEach((el) => {
    el.addEventListener("click", (btn) => {
      document.querySelector("#how-detail-screen .spaced").scrollTo({ top: 0 });
      document.body.classList.add("dark-theme");
      howDetailScreen.classList.add("active");
    });
  });
};

export default howScrolling;
