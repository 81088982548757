import "swiper/css";
import "movinwords/movinwords.css";

import { Swiper } from "swiper";

const initSlider = () => {
  const menuWhoBtn = document.getElementById("menu-who-btn");
  const menuHowBtn = document.getElementById("menu-how-btn");
  const menuWhereBtn = document.getElementById("menu-where-btn");
  const menuReachBtn = document.getElementById("menu-reach-btn");

  const mainMenu = document.getElementById("main-menu");

  const verticalNavOptions = {
    0: { top: "Who", bottom: "Vision" },
    1: { top: "Connect", bottom: "Who" },
    2: { top: "Vision", bottom: "Connect" },
  };
  const horizontalNavOptions = {
    0: { left: "How", right: "Vision" },
    1: { left: "Where", right: "How" },
    2: { left: "Vision", right: "Where" },
  };

  const frenchVerticalNavOptions = {
    0: { top: "Qui sommes-nous", bottom: "Vision" },
    1: { top: "Connecter", bottom: "Qui sommes-nous" },
    2: { top: "Vision", bottom: "Connecter" },
  };
  const frenchhorizontalNavOptions = {
    0: { left: "Comment", right: "Vision" },
    1: { left: "Où nous trouver", right: "Comment" },
    2: { left: "Vision", right: "Où nous trouver" },
  };

  const spanishVerticalNavOptions = {
    0: { top: "Quiénes", bottom: "VISIÓN" },
    1: { top: "Conectar", bottom: "Quiénes" },
    2: { top: "VISIÓN", bottom: "Conectar" },
  };
  const spanishHorizontalNavOptions = {
    0: { left: "Cómo", right: "VISIÓN" },
    1: { left: "Dónde", right: "Cómo" },
    2: { left: "VISIÓN", right: "Dónde" },
  };

  const chineseVerticalNavOptions = {
    0: { top: "对象", bottom: "使命" },
    1: { top: "联系我们", bottom: "对象" },
    2: { top: "使命", bottom: "联系我们" },
  };
  const chineseHorizontalNavOptions = {
    0: { left: "方式", right: "使命" },
    1: { left: "地点", right: "方式" },
    2: { left: "使命", right: "地点" },
  };

  const swiperSpeed = 500;

  const swiperParams = {
    initialSlide: 1,
    slidesPerView: 1,
    loop: false, // but then enable on desktop when initialising
    loopedSlides: 1,
    longSwipesRatio: 0.15,
    allowTouchMove: true,
    direction: "horizontal",
    speed: swiperSpeed,
    pagination: false,
    navigation: false,
    scrollbar: false,
    observer: true,
    spaceBetween: 0,
    on: {
      transitionStart: function () {
        document.body.classList.add("moving");
        if (this.isVertical()) {
          document.body.classList.add("movingV");
        } else {
          document.body.classList.add("movingH");
        }
      },
      transitionEnd: function () {
        document.body.classList.remove("moving");
        document.body.classList.remove("movingV");
        document.body.classList.remove("movingH");
      },
    },
  };

  const swiperHRef = new Swiper("#swiperH", {
    ...swiperParams,
    loop: true,
    // initialSlide: 4,
    breakpoints: {
      768: {
        allowTouchMove: false,
        loop: true,
        initialSlide: 1,
      },
    },
  });
  const swiperVRef = new Swiper("#swiperV", {
    ...swiperParams,
    loop: true,
    breakpoints: {
      // 640px and greater. Include full properties for each nested object
      768: {
        direction: "vertical",
        allowTouchMove: false,
        loop: true,
      },
    },
  });

  const screenSettingsBasedOnViewport = () => {
    const swiperWrapper = document.querySelector("#swiperH .swiper-wrapper");
    const hSlides = swiperWrapper.querySelectorAll(".swiper-slide");

    const firstVSlide = swiperVRef.slides[0].querySelector(".frame");
    const lastVSlide =
      swiperVRef.slides[swiperVRef.slides.length - 1].querySelector(".frame");

    if (window.innerWidth <= 768 && hSlides.length === 3) {
      let lastSlideWrapper = document.createElement("div");
      lastSlideWrapper.classList.add("swiper-slide");
      lastSlideWrapper.appendChild(lastVSlide);
      swiperWrapper.append(lastSlideWrapper);

      let firstSlideWrapper = document.createElement("div");
      firstSlideWrapper.classList.add("swiper-slide");
      firstSlideWrapper.appendChild(firstVSlide);
      swiperWrapper.prepend(firstSlideWrapper);

      setTimeout(() => {
        swiperWrapper.querySelectorAll(".swiper-slide").forEach((n, i) => {
          n.setAttribute("data-swiper-slide-index", i);
          n.classList.remove("swiper-slide-active");
          if (i === 2) {
            n.classList.add("swiper-slide-active");
          }
        });
        swiperHRef.params.initialSlide = 3;
      }, 500);
    }
    if (window.innerWidth > 768 && hSlides.length === 5) {
      window.location.reload();
    }
  };
  window.addEventListener("resize", screenSettingsBasedOnViewport, false);
  screenSettingsBasedOnViewport();

  // Menu nav

  const updateMenuNavRelateds = () => {
    mainMenu.classList.remove("active");
    document.body.classList.remove("dark-theme");

    setTimeout(() => {
      refreshNavOptions();
    }, swiperSpeed);
  };
  const moveSlideFromNav = (h, v, firstSlide, secondSlide) => {
    // debugger;

    firstSlide.slideToLoop(h, swiperSpeed);
    setTimeout(() => {
      secondSlide.slideToLoop(v, swiperSpeed);
    }, swiperSpeed);
    updateMenuNavRelateds();
  };

  menuWhoBtn.addEventListener("click", () => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      swiperHRef.slideToLoop(4);
      updateMenuNavRelateds();
    } else {
      moveSlideFromNav(1, 2, swiperHRef, swiperVRef);
    }
  });
  menuHowBtn.addEventListener("click", () => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      swiperHRef.slideToLoop(3);
      updateMenuNavRelateds();
    } else {
      moveSlideFromNav(2, 1, swiperHRef, swiperVRef);
    }
  });
  menuWhereBtn.addEventListener("click", () => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      swiperHRef.slideToLoop(1);
      updateMenuNavRelateds();
    } else {
      moveSlideFromNav(0, 1, swiperHRef, swiperVRef);
    }
  });
  menuReachBtn.addEventListener("click", () => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      swiperHRef.slideToLoop(0);
      updateMenuNavRelateds();
    } else {
      moveSlideFromNav(1, 0, swiperHRef, swiperVRef);
    }
  });

  // Navs
  const leftNavSw = document.getElementById("left-nav-sw");
  const rightNavSw = document.getElementById("right-nav-sw");
  const topNavSw = document.getElementById("top-nav-sw");
  const bottomNavSw = document.getElementById("bottom-nav-sw");

  function navLeft(event) {
    event.preventDefault();
    moveFrame(swiperHRef, swiperVRef, "prev");
  }
  function navUp(event) {
    event.preventDefault();
    moveFrame(swiperVRef, swiperHRef, "prev");
  }
  function navRight(event) {
    event.preventDefault();
    moveFrame(swiperHRef, swiperVRef, "next");
  }
  function navDown(event) {
    event.preventDefault();
    moveFrame(swiperVRef, swiperHRef, "next");
  }

  leftNavSw.addEventListener("click", navLeft);
  rightNavSw.addEventListener("click", navRight);
  topNavSw.addEventListener("click", navUp);
  bottomNavSw.addEventListener("click", navDown);

  // Navigate to any other frame, without travelling in diagonals
  function moveFrame(clickedSwiper, otherSwiper, direction) {
    const swiper = clickedSwiper;
    if (otherSwiper.realIndex === 1) {
      direction === "prev" ? swiper.slidePrev() : swiper.slideNext();
    } else {
      otherSwiper.slideToLoop(1, swiperSpeed / 2 - 70); // note small delay, so that the `moving` flag can be set for the next movement
      setTimeout(function () {
        direction === "prev" ? swiper.slidePrev() : swiper.slideNext();
        refreshNavOptions();
      }, swiperSpeed / 2);
    }
    setTimeout(() => {
      refreshNavOptions();
    }, 100);
  }

  const logos = document.querySelectorAll(".logo");
  const menuLogo = document.getElementById("menu-logo");
  logos.forEach((el) => {
    el?.addEventListener("click", () => {
      if (window.innerWidth < 768) {
        swiperHRef.slideToLoop(2, swiperSpeed);
      } else {
        swiperHRef.slideToLoop(1, swiperSpeed);
        swiperVRef.slideToLoop(1, swiperSpeed);
      }
      setTimeout(() => {
        refreshNavOptions();
      }, 150);
    });
  });

  menuLogo.addEventListener("click", () => {
    swiperHRef.slideToLoop(1, swiperSpeed);
    swiperVRef.slideToLoop(1, swiperSpeed);
    refreshNavOptions();
    mainMenu.classList.remove("active");
    document.body.classList.remove("dark-theme");
  });

  function refreshNavOptions() {
    const path = location.pathname.slice(1).replace(".html", "");
    const langLookup = {
      fr: {
        vNav: frenchVerticalNavOptions,
        hNav: frenchhorizontalNavOptions,
      },
      es: {
        vNav: spanishVerticalNavOptions,
        hNav: spanishHorizontalNavOptions,
      },
      zh: {
        vNav: chineseVerticalNavOptions,
        hNav: chineseHorizontalNavOptions,
      },
      default: {
        vNav: verticalNavOptions,
        hNav: horizontalNavOptions,
      },
    };

    const getLookup = (key, index) => {
      return langLookup[path]
        ? langLookup[path][key][index]
        : langLookup.default[key][index];
    };
    const vNav = getLookup("vNav", swiperVRef.realIndex);
    document.querySelector("#top-nav span").innerText = vNav.top;
    document.querySelector("#bottom-nav span").innerText = vNav.bottom;

    // const hNav = horizontalNavOptions[swiperHRef.realIndex];
    const hNav = getLookup("hNav", swiperHRef.realIndex);
    document.querySelector("#left-nav span").innerText = hNav.left;
    document.querySelector("#right-nav span").innerText = hNav.right;
  }
};

export default initSlider;
