import Movinwords from "movinwords/movinwords";
import Accordion from "accordion-js";
import "accordion-js/dist/accordion.min.css";
import initSlider from "./slider";
import "../styles/index.scss";
import loadMarquee from "./marquee";
import howScrolling from "./how-scrolling";

import "../assets/preview.png";

const initCustomCursor = () => {
  let cursor = document.getElementById("cursor");
  let size;
  let opacity = 1;
  document.body.addEventListener("mousemove", (ev) => {
    let path = ev.composedPath();

    if (
      path.some((x) => x.tagName == "BUTTON") ||
      path.some((x) => x.tagName == "A")
    ) {
      size = 22;
      opacity = 0.5;
    } else {
      size = 16;
      opacity = 1;
    }

    cursor.style.left = ev.clientX - size / 2 + "px";
    cursor.style.top = ev.clientY - size / 2 + "px";
    cursor.style.width = size + "px";
    cursor.style.height = size + "px";
    cursor.style.opacity = opacity;
  });
};

const loadJS = () => {
  new Accordion("#accordion1", { showMultiple: true });
  new Accordion("#accordion2", { showMultiple: true });

  const languageBtn = document.getElementById("lang-selector-js");
  const menuLanguageBtn = document.getElementById("menu-lang-selector-js");
  const languageList = document.getElementById("lang-list");
  const menuLanguageList = document.getElementById("menu-lang-list");
  const splashScreen = document.getElementById("splash-screen");
  const menuCloser = document.getElementById("menu-closer");
  const menuOpeners = document.querySelectorAll(".menu-opener");
  const whereScreenClosers = document.querySelectorAll(".detail-screen-closer");
  const mainMenu = document.getElementById("main-menu");

  languageBtn.addEventListener("click", () => {
    languageBtn.classList.toggle("active");
    languageList.classList.toggle("active");
  });
  menuLanguageBtn.addEventListener("click", () => {
    menuLanguageBtn.classList.toggle("active");
    menuLanguageList.classList.toggle("active");
  });

  // close dropdown when you click anywhere on the screen
  document.addEventListener("click", function (event) {
    var isClickInside = languageBtn.contains(event.target);
    if (!isClickInside) {
      languageBtn.classList.remove("active");
      languageList.classList.remove("active");
    }
    var isClickInsideMenu = menuLanguageBtn.contains(event.target);
    if (!isClickInsideMenu) {
      menuLanguageBtn.classList.remove("active");
      menuLanguageList.classList.remove("active");
    }
  });

  document.addEventListener("DOMContentLoaded", function (event) {
    document.body.classList.add("lock-menu");
    const edgeItems = document.querySelectorAll(".edge-nav");
    edgeItems.forEach((el) => {
      el.classList.add("active");
    });

    setTimeout(() => {
      document.getElementById("splashTop").style.display = "none";
    }, 300);
    setTimeout(() => {
      splashScreen.classList.add("hide");
    }, 1900);
    setTimeout(() => {
      splashScreen.classList.add("close");
      edgeItems.forEach((el) => {
        el.classList.remove("active");
      });
      new Movinwords({
        el: ".js-print-out",
        duration: 2000,
        delay: 200,
        wordSpacing: 1,
      });
      document.body.classList.remove("lock-menu");
    }, 2500);
  });

  // MENU RELATED SCRIPTS
  menuOpeners.forEach((element) => {
    element.addEventListener("click", () => {
      mainMenu.classList.add("active");
      document.body.classList.add("dark-theme");
    });
  });

  menuCloser.addEventListener("click", () => {
    mainMenu.classList.remove("active");
    document.body.classList.remove("dark-theme");
  });

  // PRIVACY OPENER
  const legalOpener = document.getElementById("legal-opener");
  const legalScreen = document.getElementById("legal-screen");
  legalOpener.addEventListener("click", (e) => {
    e.preventDefault();
    legalScreen.querySelector(".spaced").scrollTo({ top: 0 });
    legalScreen.classList.add("active");
    mainMenu.classList.remove("active");
    document.body.classList.remove("dark-theme");
  });

  const privacyOpener = document.getElementById("privacy-opener");
  const privacyScreen = document.getElementById("privacy-screen");
  privacyOpener.addEventListener("click", (e) => {
    e.preventDefault();
    privacyScreen.querySelector(".spaced").scrollTo({ top: 0 });
    privacyScreen.classList.add("active");
    mainMenu.classList.remove("active");
    document.body.classList.remove("dark-theme");
  });

  const closeDetailScreens = () => {
    document.body.classList.remove("dark-theme");
    const detailScreens = document.querySelectorAll(".detail-screen");
    detailScreens.forEach((el) => {
      el.classList.remove("active");
      document.body.classList.remove("dark-theme");

      // remove animated stat element's classes to re-animate on open
      const stats = el.querySelectorAll(".animated-stats");
      if (stats) {
        stats.forEach((statEl) => {
          statEl.querySelectorAll("li").forEach((liEl) => {
            liEl.classList.remove("active");
          });
        });
      }
    });
  };

  // WHERE SCREEN RELATED SCRIPTS
  whereScreenClosers.forEach((element) => {
    element.addEventListener("click", () => {
      closeDetailScreens();
    });
  });

  document.addEventListener("keydown", function (e) {
    let keyCode = e.key;

    if (keyCode === "Escape") {
      closeDetailScreens();
      mainMenu.classList.remove("active");
      document.body.classList.remove("dark-theme");
    }
  });

  const whereScreenOpeners = document.querySelectorAll(
    ".where-screen ul button"
  );

  whereScreenOpeners.forEach((element) => {
    element.addEventListener("click", () => {
      const whereScreenDetailEl = document.getElementById(
        `where-${element.dataset.id}-detail`
      );
      whereScreenDetailEl.querySelector(".spaced")?.scrollTo({ top: 0 });
      whereScreenDetailEl.classList.add("active");
      document.body.classList.add("dark-theme");
    });
  });

  const whereDetailScrollElements = document.querySelectorAll(
    ".detail-screen .spaced"
  );

  function isElementVisible(el, holder, offset = 100) {
    holder = holder || document.body;
    const { top: topH, bottom: bottomH, height } = el.getBoundingClientRect();
    const bottom = bottomH + offset;
    const top = topH + offset;
    const holderRect = holder.getBoundingClientRect();

    return top <= holderRect.top
      ? holderRect.top - top <= height
      : bottom - holderRect.bottom <= height;
  }
  whereDetailScrollElements.forEach((el) => {
    el.addEventListener("scroll", (event) => {
      const stats = el.querySelectorAll(".animated-stats");
      if (stats && stats.length) {
        const animatedEls =
          stats.length > 1
            ? [
                ...stats[0].querySelectorAll("li"),
                ...stats[1].querySelectorAll("li"),
              ]
            : stats[0].querySelectorAll("li");
        animatedEls.forEach((animatedEl) => {
          if (isElementVisible(animatedEl)) {
            setTimeout(() => {
              animatedEl.classList.add("active");
            }, 300);
          }
        });
      }
    });
  });

  function doOnOrientationChange() {
    if (window.innerWidth > 768) {
      return;
    }
    switch (window.orientation) {
      case -90:
        document.getElementById("landscape").style.display = "flex";
        break;
      case 90:
        document.getElementById("landscape").style.display = "flex";
        break;
      default:
        document.getElementById("landscape").style.display = "none";
        break;
    }
  }
  doOnOrientationChange();
  //Listen to orientation change
  window.addEventListener("orientationchange", doOnOrientationChange);

  const appHeight = () => {
    document.body.style.setProperty("--vh-100", `${window.innerHeight}px`);
  };

  window.addEventListener("resize", appHeight);
  appHeight();
};

const init = () => {
  document.addEventListener("DOMContentLoaded", function (event) {
    initCustomCursor();
  });
  if (location.pathname.includes("/error")) {
    return false;
  }
  initSlider();
  loadJS();
  howScrolling();
  loadMarquee();
};
init();
