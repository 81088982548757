const loadMarquee = () => {
  const createMarquee = ({ el, direction = "left" }) => {
    const containerEl = document.querySelector(el);

    const marqueeEl = containerEl.querySelector(".marqueeContent");

    const speed = 35;
    const pauseOnHover = true;
    const loop = true;
    const play = true;

    const init = () => {
      const containerWidth = containerEl.clientWidth;
      const marqueeWidth = marqueeEl.clientWidth;

      const setFillGaps = () => {
        const loopCount = Math.ceil(
          containerEl.clientWidth / marqueeEl.clientWidth
        );

        while (containerEl.children.length > 1) {
          containerEl.removeChild(containerEl.lastChild);
        }
        for (let index = 0; index < loopCount; index++) {
          containerEl.appendChild(containerEl.children[0].cloneNode(true));
        }
      };

      setFillGaps();

      const duration =
        marqueeWidth < containerWidth
          ? containerWidth / speed
          : marqueeWidth / speed;

      containerEl.style.setProperty("--marquee-duration", `${duration}s`);
      containerEl.style.setProperty(
        "--marquee-pause-on-hover",
        pauseOnHover ? "paused" : "running"
      );
      containerEl.style.setProperty(
        "--marquee-direction",
        direction === "left" ? "normal" : "reverse"
      );
      containerEl.style.setProperty("--marquee-loop", loop);
    };

    let resizeTimer;

    const onResize = () => {
      init();
      // for restarting the animation on resize
      containerEl.classList.add("noAnimation");
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        containerEl.classList.remove("noAnimation");
      }, 300);
    };

    init();

    window.addEventListener("resize", onResize);
  };

  createMarquee({
    el: "#marquee1",
  });
  createMarquee({
    el: "#marquee2",
    direction: "right",
  });
};

export default loadMarquee;
